:root {
  --primary-one: #C241FF;
  --primary-two: #EA7EFF;
  --primary-three: #D272FF;
  --primary-four: #9D1ED9;
  --primary-five: #881BBC;
  --primary-six: #432252;
  --secondary-one: #FFFF51;
  --secondary-two: #D4B70D;
  //bgs
  --dark-bg: #000;
  --light-bg: #fff;
  --grey-one: #EDEEEE;
  --grey-two: #585858;
  --grey-three: #d1d1d1;
  --grey-four: #adadad;
  --primary-bg: #F4BFFF;
  --primary-light-bg: #F5E8FB;
  --secondary-light-bg: #F9F4ED;
  //texts
  --dark-text: #000;
  --light-text: #fff;
  --transparent: transparent;
  --transparent-primary: rgb(244, 191, 255, 0.4);
  --transparent-two: rgba(0, 0, 0, 0.479);
  --light-transparent: rgba(255, 255, 255, 0.6);
  --grey-one-transparent: rgba(237, 238, 238, 0.8);
}


body .dark {
  --primary-one: #C241FF;
  --primary-two: #432252;
  --primary-three: #D272FF;
  --primary-four: #9D1ED9;
  --primary-five: #881BBC;
  --primary-six: #EA7EFF;
  --secondary-one: #D4B70D;
  --secondary-two: #e4c201;
  //bgs
  --dark-bg: #fff;
  --light-bg: #000;
  --grey-one: #292828;
  --grey-two: #d1d1d1;
  --grey-three: #717272;
  --grey-three: #474747;
  --primary-bg: #F4BFFF;
  --primary-light-bg: #827c85;
  --secondary-light-bg: #271e01;
  //texts
  --dark-text: #fff;
  --light-text: #000;
  --transparent-primary: rgb(244, 191, 255, 0.4);
  --transparent-two: rgba(255, 255, 255, 0.479);
  --light-transparent: rgba(0, 0, 0, 0.6);
  --grey-one-transparent: rgba(41, 40, 40, 0.8);
}


body, body .dark {
  color: var(--dark-text);
  background-color: var(--light-bg);
  transition: background-color 0.2s, color 0.2s;
  transition-timing-function: cubic-bezier(0.88, 0.21, 0.47, 0.96);
}

//text sizes
$xltext: 10vw;

$breakpoint-mobile: 440px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 981px;
$breakpoint-larger: 1920px;

$space-3: 3px;
$space-5: 5px; //for text
$space-10: 10px;
$space-20: 20px;
$space-30: 30px;
$space-40: 30px;
$space-50: 50px;
$space-8v: 8vh;

// @import 'animate.css';
// @import '~loaders.css/src/animations/pacman.scss';
// @import '~loaders.css/src/animations/square-spin.scss';
// @import './components/Layout/LoaderSquare.scss';

@font-face{
  font-family: 'Nunito-black';
  src: url('../public/assets/fonts/Nunito-Black.ttf');
}

@font-face{
  font-family: 'Nunito-semibold';
  src: url('../public/assets/fonts/Nunito-SemiBold.ttf');
}

@font-face{
  font-family: 'Nunito-bold';
  src: url('../public/assets/fonts/Nunito-Bold.ttf');
}

@font-face{
  font-family: 'Roboto-bold';
  src: url('../public/assets/fonts/Roboto-Bold.ttf');
}
@font-face{
  font-family: 'Roboto-regular';
  src: url('../public/assets/fonts/Roboto-Regular.ttf');
}
@font-face{
  font-family: 'Roboto-light';
  src: url('../public/assets/fonts/Roboto-Light.ttf');
}


body{
  --base-text: 1.1vw;
  --button-one: calc(var(--base-text) * 1.4); 
  --button-two: calc(var(--base-text) * 2.25); 
  --button-three: calc(var(--base-text) * 1.2); 
  --button-four: calc(var(--base-text) * 1.4); 
  --blockquote: calc(var(--base-text) * 2);
  --h-seven: calc(var(--base-text) * 1.3);
  --h-five: calc(var(--base-text) * 1.8);
  --h-four: calc(var(--base-text) * 2); 
  --h-three: calc(var(--base-text) * 2); 
  --h-two: calc(var(--base-text) * 3.3); 
  --h-one: calc(var(--base-text) * 4.5);
  --h-humongous: 7vw;

  --space-base: 5px;
  --space-1: calc(var(--space-base) * 0.2);
  --space-3: calc(var(--space-base) * 0.6);
  --space-5: calc(var(--space-base) * 1); 
  --space-10: calc(var(--space-base) * 2);
  --space-15: calc(var(--space-base) * 3);
  --space-20: calc(var(--space-base) * 4);
  --space-25: calc(var(--space-base) * 5);
  --space-30: calc(var(--space-base) * 6);
  --space-35: calc(var(--space-base) * 7);
  --space-40: calc(var(--space-base) * 8);
  --space-50: calc(var(--space-base) * 10);
  --space-8v: 8vh;
  --space-10h: 200px;
  --base-icon: calc(var(--space-base) * 4.2);
  --icon-two: calc(var(--base-icon) * 1.5);

  --img-one: calc(var(--base-icon) * 3);
  --img-two: calc(var(--base-icon) * 30);
  --img-three: calc(var(--base-icon) * 10);

  --border-radius-one: calc(var(--space-base) * 4);
  --border-radius-two: calc(var(--space-base) * 8);
  --border: 2px solid var(--dark-bg);
}

@media screen and (min-width: $breakpoint-larger){
  body, p{
      --base-text: 18px;
  }
}
@media screen and (max-width: $breakpoint-desktop){
  body, p{
      --base-text: 0.9vw;
  }
}
@media screen and (max-width: $breakpoint-tablet){
  body, p{
    --base-text: 14px; 
    --button-one: calc(var(--base-text) * 1.2); 
    --button-two: calc(var(--base-text) * 1.4); 
    --button-three: calc(var(--base-text) * 1.6); 
    --button-four: calc(var(--base-text) * 1.6);
    --blockquote: calc(var(--base-text) * 1.4);
    --h-five: calc(var(--base-text) * 1.2);
    --h-four: calc(var(--base-text) * 1.2); 
    --h-three: calc(var(--base-text) * 1.2); 
    --h-two: calc(var(--base-text) * 3.1); 
    --h-one: calc(var(--base-text) * 3.5);
 }
}
@media screen and (max-width: $breakpoint-mobile){
  body, p{
      --base-text: 12px; 
      --button-one: calc(var(--base-text) * 1.2); 
      --button-two: calc(var(--base-text) * 1.4); 
      --button-three: calc(var(--base-text) * 1.6); 
      --button-four: calc(var(--base-text) * 1.6);
      --blockquote: calc(var(--base-text) * 1.4);
      --h-five: calc(var(--base-text) * 1.2);
      --h-four: calc(var(--base-text) * 1.2); 
      --h-three: calc(var(--base-text) * 1.2); 
      --h-two: calc(var(--base-text) * 3.1); 
      --h-one: calc(var(--base-text) * 3.5);
   }
}


//scrollbar
::-webkit-scrollbar {
  background-color: transparent;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--primary-one) 0, var(--primary-five) 100%);
  border: var(--transparent-bg);
  border-radius: 20px;
  box-shadow: inset 0 0 5px var(--primary-color);
}

::-webkit-scrollbar-track {
  -webkit-backdrop-filter: blur(6px)!important;
  backdrop-filter: blur(6px)!important;
  background: transparent;
  display: none;
  max-width: 5px;
}

@media (max-width: $breakpoint-tablet) {
  ::-webkit-scrollbar {
    display:none;
  }
}

//yongphum

:focus-visible{
  outline: var(--primary-one) auto 1px;
  box-shadow: 2px 2px 20px var(--light-bg);
}

::selection {
  color: #fff;
  background: var(--primary-one);
}
body {
  -webkit-tap-highlight-color: transparent;
}



// font styles
strong{
  font-family: 'Nunito-black';
  font-size: var(--h-seven);
}
.h-humongous{
  font-family: 'Nunito-black';
  font-size: var(--h-humongous);
}
.h-humongous-one{
  font-family: 'Nunito-black';
  font-size: 12vw;
}
h1{
  font-family: 'Nunito-semibold';
  font-size: var(--h-one);
  padding: var(--space-5) 0;
}
h2{
  font-family: 'Roboto-bold';
  font-size: var(--h-two);
  padding: var(--space-10) 0;
}
h3, .h3, .h7.h3{
  font-family: 'Nunito-black';
  font-size: var(--h-three);
}
h4{
  font-family: 'Roboto-light';
  font-size: var(--h-four);
}
h5{
  font-family: 'Roboto-bold';
  font-size: var(--h-five);
}
h6{
  font-family: 'Nunito-semibold';
  font-size: var(--h-five);
}
.h7{
  font-family: 'Nunito-semibold';
  font-size: var(--h-seven);
}
blockquote{
  font-family: 'Nunito-bold';
  font-size: var(--blockquote);
}
button.button-one, .button-one, .link-button{
  font-family: 'Nunito-black';
  font-size: var(--button-one);
}
button.button-two, .button-two{
  font-family: 'Nunito-black';
  font-size: var(--button-two);
}
button.button-three, .button-three{
  font-family: 'Nunito-semibold';
  font-size: var(--button-three);
}
button.button-four, .button-four{
  font-family: 'Nunito-black';
  font-size: var(--button-four);
}
.embolden{
  font-family: 'Nunito-black';
}
.input-field::placeholder,
.message-field::placeholder{
  color: var(--grey-four);  
  font-family: 'Nunito-semibold';
  font-size: var(--h-seven);
  }
.input-field,
.message-field{
  font-family: 'Nunito-semibold';
  font-size: var(--h-seven);
}
.italics{
  font-style: italic;
}

a{
  color: var(--primary-one);
}
body{
  font-size: var(--base-text);
}
p{
  padding: var(--space-20) 0;
  line-height: 1.4em;
}
.spaced-10{
  padding: var(--space-10) 0;
}

//global
.light-txt{
  color: var(--light-text);
}

blockquote{
  position: relative;
  margin: calc(var(--blockquote) * 2) calc(var(--blockquote) * 1);
}
blockquote::before {
  content: '“';  
  font-size: calc(var(--blockquote) * 4);  
  line-height: 0;    
  color: var(--primary-three);  
  position: absolute;  
  left: calc(var(--blockquote) * -1.8);  
  top: calc(var(--blockquote) * 0.2);  
}

 
blockquote::after {
  display: none;
  content: '”';  
  font-size: calc(var(--blockquote) * 4);  
  line-height: 0;  
  //vertical-align: -0.1em;  
  color: var(--primary-three); 
  position: absolute;  
  right: calc(var(--blockquote) * -1);  
  bottom: calc(var(--blockquote) * -6);  
}

.App, .dark, .light, .App.dark{
  > div{
    overflow: hidden;
    //flex-direction: 1;
  }
  .layout-bg{
    min-height: 30vh;
  }
}
html {
  scroll-behavior: smooth;
}

